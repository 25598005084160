import axios from "axios";

export default {
  list() {
    return axios.get("crm/menu/meal/combo");
  },

  add(payload) {
    return axios.post("crm/menu/meal/combo", payload);
  },

  update(payload) {
    return axios.post("crm/menu/meal/combo/update", payload);
  },

  delete(payload) {
    return axios.delete("crm/menu/meal/combo", { data: payload });
  },
};
