<template>
  <div>
    <el-dialog :title="title" :visible.sync="setShow" width="30%">
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item prop="item">
              <el-input type="text" v-model="form.item" placeholder="Search for a combination" @input="search" />
              <div class="mt-10 mb-10 text-secondary font-sm text-cursor" @click="addCombination">
                <i class="eden-icon-add font-sm mr-1 text-secondary" /> Create a
                new combination
              </div>
              <div v-for="(combo, i) in combos" :key="i" class="is-flex is-align-center card font-sm mb-10">
                <el-checkbox v-model="combo.checked" @input="enableAdd(combo)" class="mr-1" />
                <div class="eden-meal--image">
                  <img v-if="combo.image" :src="getThumbnailFromCloudinary(combo.image)" alt="Image" />
                </div>
                <div>
                  <p style="display: block">{{ item.name }} with</p>
                  <p class="text-bold">{{ combo.combo }}</p>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" @click="add" :loading="adding" :disabled="!showAdd">Add</el-button>
      </span>
    </el-dialog>
    <combination-form :show.sync="combination.visibility" :action="combination.action" :item="item"
      :is-published="isPublished" @update:combo="updateCombinations" @success="updateCombinations" />
  </div>
</template>

<script>
import CombinationForm from "@/components/Services/Meal/Menu/NewMenu/CombinationForm";

export default {
  name: "Combinations",
  components: { CombinationForm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        item: "",
      },
      adding: false,
      updating: false,
      combos: [],
      combination: {
        action: "add",
        visibility: false,
        data: {
          mealItem: this.item,
        },
      },
      allCombos: [],
      showAdd: false,
      checkedCombos: [],
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.item.name;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.checkedCombos = this.item.combos.filter((item) => item.checked);
        if (!this.item.added_combos) this.item.added_combos = [];
        this.allCombos = this.configureCombos(
          [...this.item.preset_combos_full, ...this.item?.added_combos],
          this.item.id,
        );

        this.combos = this.allCombos;
      }
    },
  },
  methods: {
    configureCombos(items = [], meal_id) {
      return items.map((combo) => {
        let availableSides = Object.keys(combo).filter((item) =>
          item.includes("sides"),
        );
        if (!availableSides.length) {
          return [];
        } else {
          let sideItem = "";
          availableSides.map((side) => {
            if (availableSides.some((side) => side.endsWith('_name'))) {
              // Delete all keys that end with "_sides" in the combo object
              availableSides.forEach((side) => {
                if (side.endsWith('_sides')) {
                  delete combo[side];
                }
              });
            }
            if (combo[side]) sideItem += "," + combo[side];
          });
          if (this.checkedCombos.length) {
            let combosArr = this.checkedCombos.filter((comboCheck) => {
              if (comboCheck.id === combo.id) {
                return {
                  id: comboCheck.id,
                  combo: sideItem.slice(1),
                  image: combo.image,
                  checked: comboCheck.checked,
                  meal_id,
                }
              }
            });
            if (combosArr.length) {
              return { ...combosArr[0], meal_id };
            }
            return {
              id: combo.id,
              combo: sideItem.slice(1),
              image: combo.image,
              checked: false,
              ...combo,
              meal_id,
            };
          } else {
            return {
              id: combo.id,
              combo: sideItem.slice(1),
              image: combo.image,
              checked: false,
              ...combo,
              meal_id,
            };
          }
        }
      });
    },
    enableAdd(value) {
      if (value.checked) {
        this.checkedCombos.push(value);
      } else {
        this.checkedCombos = this.checkedCombos.filter(
          (combo) => combo.id !== value.id,
        );
      }
      this.showAdd = !!this.checkedCombos.length;
    },
    search() {
      if (this.form.item) {
        this.combos = this.allCombos.filter((item) =>
          item.combo.toLowerCase().includes(this.form.item.toLowerCase()),
        );
      } else {
        this.combos = this.allCombos;
      }
    },
    updateCombinations(evt) {
      const modifiedCombo = {
        ...evt,
        protein_sides: evt.protein_sides_name,
        main_sides: evt.main_sides_name,
        sauce_sides: evt.sauce_sides_name,
        other_sides: evt.other_sides_name,
      };
      // Delete object keys that end with 'name'
      Object.keys(modifiedCombo).forEach(key => {
        if (key.endsWith('name')) {
          delete modifiedCombo[key];
        }
      });
      // Add modifiedCombo to the configuredCombo array
      const configuredCombo = this.configureCombos([modifiedCombo], this.item.id);
      // Filter out duplicates before updating the combos array
      this.combos = [...this.combos, ...configuredCombo];
      this.$emit("success", [{ ...evt, meal_id: this.item.id }]);
    },
    addCombination() {
      this.combination = {
        action: "add",
        visibility: true,
        data: {
          mealItem: this.item,
        },
      };
    },
    closeEvent() {
      this.setShow = false;
      this.form.item = "";
    },
    add() {
      let setCombos = {
        id: this.item.id,
        combos: this.checkedCombos.filter(
          (combo) => combo.meal_id === this.item.id,
        ),
      };
      this.$emit("checkedCombos", setCombos);
      this.closeEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.eden-meal--image {
  height: 36px !important;
  width: 36px !important;
  min-height: 36px;
  min-width: 36px;
  overflow: hidden;
  border-radius: 100%;
  background: #798b83;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 100%;
    background: #e9fbf2;
  }

  +div {
    width: 70%;
  }
}
</style>
