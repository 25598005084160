<template>
  <div>
    <eden-form-section
      title="Menu Window"
      subtitle="What days is this menu active for? (Note: Menus should be created for just one week; for Mon - Sat)"
    >
      <template slot="form-fields">
        <el-row type="flex">
          <el-col :md="24">
            <el-form-item label="Date range">
              <div class="date-picker">
                <el-date-picker
                  v-model="setDateRange"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="From"
                  end-placeholder="To"
                  format="dd MMM, yyyy"
                  value-format="yyyy-MM-dd"
                  @change="updateDate"
                  :picker-options="{
                    disabledDate: disabledDates,
                  }"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <eden-form-section
      title="Menu Type"
      subtitle="What menu type does this menu fall under?"
    >
      <template slot="form-fields">
        <el-row type="flex">
          <el-col :md="24">
            <el-form-item label="">
              <el-select
                v-model="setMenuType"
                filterable
                placeholder="Select a menu type"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in filteredMenuTypes"
                  :value="item.id"
                  :key="index"
                  :label="item.name"
                ></el-option>
              </el-select>
              <!-- <el-select
                v-model="setMenuType"
                filterable
                placeholder="Select a menu type"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in menuTypes"
                  :value="item.id"
                  :key="index"
                  :label="item.name"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <eden-form-section
      title="Meal Items"
      subtitle="What main meal items should appear in this menu?"
    >
      <template slot="form-fields">
        <el-row type="flex">
          <el-col :md="24">
            <!-- <pre>{{ setMenuItems }}</pre> -->
            <el-form-item label="">
              <el-select
                v-model="setMenuItems"
                multiple
                filterable
                :loading="loading"
                placeholder="Select a main meal item"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in mealClassItems"
                  :value="item"
                  :key="index"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="menuItems.length" type="flex" class="flex-wrap">
          <el-col :md="24">
            <div class="menu-meals">
              <div class="heading">
                <p>Selected Meal Items ({{ menuItems.length }})</p>
                <p
                  v-if="menuItems.length"
                  class="is-underline text-cursor text-danger"
                  @click="clearSelectionWindow"
                >
                  Clear selection
                </p>
              </div>
              <div v-if="menuItems.length" class="meals">
                <el-row>
                  <el-col
                    :md="11"
                    class="item"
                    v-for="(meal, i) in setMenuItems"
                    :key="i"
                  >
                    <meal-item
                      :meal-item="meal"
                      @update:mealItem="handleMealUpdate($event, i)"
                      @remove="removeItem"
                      :key="i"
                    />
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <eden-confirm-dialog
      title="Clear selection window"
      button-text="Yes, clear"
      button-type="danger"
      :show.sync="showClearSelection"
      @confirm="clear"
    >
      <p>Are you sure you want to clear your meal item selection?</p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import MealItem from "@/components/Services/Meal/MealItems/MealItem";
export default {
  name: "MenuWindow",
  props: {
    dateRange: {
      type: Array,
      default() {
        return [];
      },
    },
    menuType: {
      type: [String, Number],
      required: false,
    },
    menuItems: {
      type: Array,
      default() {
        return [];
      },
    },
    mealClassItems: {
      type: Array,
      default() {
        return [];
      },
    },
    menuTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MealItem,
  },
  data() {
    return {
      showClearSelection: false,
    };
  },
  computed: {
    setDateRange: {
      get() {
        return this.dateRange;
      },
      set(value) {
        this.$emit("update:dateRange", value);
      },
    },
    setMenuItems: {
      get() {
        return this.menuItems;
      },
      set(value) {
        this.$emit("update:menuItems", value);
      },
    },
    setMenuType: {
      get() {
        return this.menuType;
      },
      set(value) {
        this.$emit("update:menuType", value);
      },
    },
    filteredMenuTypes() {
      return this.menuTypes.filter((item) => item.name !== "Basic");
    },
  },
  methods: {
    removeItem(value) {
      this.setMenuItems = this.setMenuItems.filter(
        (meal) => meal.id !== value.id,
      );
    },
    clearSelectionWindow() {
      this.showClearSelection = true;
    },
    clear() {
      this.setMenuItems = [];
      this.showClearSelection = false;
    },
    updateDate(value) {
      this.setDateRange = value;
      console.log(this.dateRange);
    },
    handleMealUpdate(newItem, index) {
      this.setMenuItems.splice(index, 1, newItem);
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }

      const yesterday = new Date(
        new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
      );
      return (
        time.getTime() < yesterday || (time.getDay() != 1 && time.getDay() != 6)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-meals {
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 12px 0px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 8px;
    font-size: 12px;
  }

  .meals {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;

    .item {
      padding: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin: 0 8px 8px;

      i {
        margin-left: 10px;
        color: #798b83;
        cursor: pointer;
      }
      &.invalid {
        color: #e36262;
        i {
          color: #e36262;
        }
      }
    }
  }
}
</style>
