<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="setShow"
      @close="closeEvent"
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="form">
        <p v-if="action == 'edit' && item" class="text-center mb-20">
          <span class="editText text-bold">Editing: {{ item.combo }}</span>
        </p>
        <el-row>
          <el-col :span="24" class="is-flex is-justify-center">
            <eden-image-upload
              :image-size="60"
              :image-url.sync="form.image"
              :image-text="'combo image'"
              :image-folder="'services/meal/combo'"
            />
          </el-col>
        </el-row>
        <el-row v-for="(side, i) in item.side_types" :key="i" type="flex">
          <el-col :md="24">
            <el-form-item
              :label="`${formatText(side.split('_').join(' '))}`"
              :prop="side"
            >
              <el-select
                v-model="form[side]"
                filterable
                clearable
                :placeholder="`Select this combo's ${side.split('_')[0]}`"
                @change="setComboName"
              >
                <el-option
                  v-for="(sideItem, index) in getSides(side)"
                  :key="index"
                  :label="sideItem.name"
                  :value="sideItem.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Calorie Count" prop="calorie_count">
              <el-input type="text" v-model="form.calorie_count" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :md="24">
            <el-form-item label="Food tags" prop="food_tags">
              <el-select
                v-model="form.food_tags"
                multiple
                filterable
                allow-create
                placeholder="Add a tag"
              >
                <el-option
                  v-for="(tag, index) in mealFoodTags"
                  :key="index"
                  :label="tag.name"
                  :value="tag.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="action === 'add'">
          <el-col :span="24">
            <el-form-item label="Chef's notes" prop="chef_notes">
              <el-input
                type="textarea"
                v-model="form.chef_notes"
                :autosize="{ minRows: 3, maxRows: 3 }"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button
          v-if="action === 'add'"
          type="primary"
          @click="add"
          :loading="adding"
          >Create combination</el-button
        >
        <el-button
          v-else
          type="primary"
          @click="update"
          :loading="updating"
          :disabled="!form.name"
          >Save changes</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import foodTags from "@/requests/services/meal/food-tags";
import combos from "@/requests/services/meal/combos";

export default {
  name: "CombinationForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        image: "",
        protein: "",
        calorie_count: "",
        food_tags: [],
        chef_notes: "",
      },
      adding: false,
      updating: false,
      mealFoodTags: [],
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    mealItems() {
      return this.$store.getters.meal_item_list.filter(
        (item) => item.meal_type === "side",
      );
    },
    title() {
      return `${this.action === "add" ? "Add a" : "Edit"} combination`;
    },
    mainSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "main_sides";
      });
    },
    proteinSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "protein_sides";
      });
    },
    otherSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "other_sides";
      });
    },
    sauceSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "sauce_sides";
      });
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        this.form.name = this.item.name || this.item.combo;
        this.form.image = this.item.image || this.item.image_url;
        this.form.calorie_count = this.item.calorie;
        this.form.food_tags = this.item.tags_id;
      }
    },
  },
  mounted() {
    this.getMealFoodTags();
  },
  methods: {
    closeEvent() {
      this.item.side_types?.forEach((item) => {
        this.form[item] = "";
      });
      this.form.name = "";
      this.form.image = "";
      this.form.calorie_count = "";
      this.form.food_tags = [];
      this.form.chef_notes = "";
      this.setShow = false;
    },
    add() {
      if (!this.form.image && this.isPublished) {
        this.$message.error(
          "Please an image is required for all combos in an already published menu!",
        );
        return;
      }
      this.adding = true;
      const payload = {
        meal_id: this.item.id,
        ...this.form,
        tags_id: this.form.food_tags,
        calorie: this.form.calorie_count,
      };
      console.log(payload, "payload")
      combos
        .add(payload)
        .then((response) => {
          console.log(response.data, "datea")
          if (response.data.status) {
            this.adding = false;
            this.$emit("success", response.data.data);
            this.$message.success(response.data.message);
            this.closeEvent();
          } else {
            this.adding = false;
          }
        })
        .catch((error) => {
          this.adding = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getMealFoodTags() {
      foodTags.list().then((response) => {
        if (response.data.status) {
          this.mealFoodTags = this.sortArray({
            data: response.data.data,
            property: "name",
          });
        }
      });
    },
    getSides(side) {
      switch (side) {
        case "main_sides":
          return this.mainSides;
        case "protein_sides":
          return this.proteinSides;
        case "other_sides":
          return this.otherSides;
        case "sauce_sides":
          return this.sauceSides;
        default:
          break;
      }
    },
    setComboName() {
      const { main_sides, protein_sides, other_sides, sauce_sides } = this.form;

      const main_side = this.mainSides.filter((item) => item.id === main_sides);
      const protein_side = this.proteinSides.filter(
        (item) => item.id === protein_sides,
      );
      const other_side = this.otherSides.filter(
        (item) => item.id === other_sides,
      );
      const sauce_side = this.sauceSides.filter(
        (item) => item.id === sauce_sides,
      );

      const combo = this.concatenateItems(
        main_side[0]?.name,
        protein_side[0]?.name,
        other_side[0]?.name,
        sauce_side[0]?.name,
      );

      this.form.name = `${this.item.name}, ${combo}`;
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          console.log("not valid");
          return false;
        }
        console.log(" valid");

        this.updating = true;
        this.$emit("update:combo", {
          ...this.item,
          image: this.form.image,
          calorie: this.form.calorie_count,
          tags_id: this.form.food_tags,
          edited: true,
        });
        this.updating = false;
        this.closeEvent();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.editText {
  background-color: var(--eden-orange-septenary);
  border-radius: 8px;
  padding: 8px;
}
</style>
