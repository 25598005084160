<template>
  <div v-loading="prefilling" class="eden-page-form wider">
    <el-form :model="form" label-position="top" ref="form">
      <template v-if="step === 1">
        <menu-window
          :date-range.sync="form.daterange"
          :menu-items.sync="form.menu_items"
          :meal-class-items="mealClassItems"
          :menu-types="menuTypes"
          :is-published="isPublished"
          :menu-type.sync="form.menu_type_id"
        />
      </template>
      <template v-else-if="step === 2">
        <combinations-review
          :menu-items.sync="form.menu_items"
          :is-published="isPublished"
        />
      </template>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <span class="eden-page-form__actions">
              <el-button plain @click="cancel" v-if="step === 1"
                >Cancel</el-button
              >
              <el-button plain @click="step = 1" v-else>Back</el-button>
              <el-button
                v-if="showDraftButton"
                type="plain"
                :loading="updating"
                @click="update"
                :disabled="!form.menu_items?.length && !!form.daterange?.length"
                class="mr-10"
                >Save as draft
              </el-button>
            </span>
            <el-button
              v-if="step === 1"
              type="primary"
              @click="next"
              :disabled="!form.menu_items?.length"
              >Next
            </el-button>
            <el-button v-else type="primary" :loading="adding" @click="addMenu"
              >{{ showPublishTitle ? "Publish menu" : "Update menu" }}
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
    <eden-confirm-dialog
      :title="
        showPublishTitle ? 'Publish this menu?' : 'Edit this published menu?'
      "
      :button-text="showPublishTitle ? 'Yes, publish' : 'Yes, update'"
      button-type="success"
      :show.sync="showPublish"
      @confirm="publish"
      :button-status="adding"
    >
      <p>
        Are you sure you want to
        {{ showPublishTitle ? "publish" : "edit" }}
        this menu?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import MenuWindow from "@/components/Services/Meal/Menu/NewMenu/MenuWindow";
import CombinationsReview from "@/components/Services/Meal/Menu/NewMenu/CombinationsReview";
import * as actions from "@/store/action-types";

// import meal from "@/requests/services/meal";
import menu from "@/requests/services/meal/menu";

export default {
  name: "MenuForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    serviceTypeId: {
      type: [String, Number],
      default: "",
    },
    serviceType: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    MenuWindow,
    CombinationsReview,
  },
  data() {
    return {
      prefilling: true,
      step: 1,
      form: {
        daterange: [],
        menu_items: [],
        service: "Meal",
        image: null,
        combo_image: "",
        service_type_name: "",
        meal_type: "",
        menu_type_id: "",
        class_category: null,
        side_category: "",
        main_with_sides: false,
        side_types: [],
        food_tags: [],
        chef_note: "",
        preset_combos: [],
      },
      mealClassItems: [],
      adding: false,
      updating: false,
      showPublish: false,
    };
  },
  computed: {
    mealItem() {
      return {
        name: this.form.service_type_name,
        side_types: this.form.side_types,
        ingredients: this.form.ingredients,
        food_tags: this.form.food_tags,
        chef_note: this.form.chef_note,
      };
    },
    mealItems() {
      return this.$store.getters.meal_item_list
        .filter(
          (item) =>
            item.meal_type === "main" || item.meal_type?.includes("main"),
        )
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    },
    menuTypes() {
      return this.$store.getters.menu_types;
    },
    foodTags() {
      return this.$store.getters.food_tags;
    },
    isPublished() {
      return this.serviceType.is_published;
    },
    showPublishTitle() {
      return this.action === "add" || !this.serviceType.is_published;
    },
    showDraftButton() {
      let value = false;
      if (this.action === "add") {
        value = true;
      } else {
        if (!this.serviceType.is_published) value = true;
      }

      return value;
    },
  },
  created() {
    this.callActions();
  },
  watch: {
    action(newAction) {
      if (newAction === "edit") {
        console.log("hey you");
        this.prefilling = true;
        this.prefillForm();
        this.prefilling = false;
      }
    },
  },

  methods: {
    async callActions() {
      if (this.action === "edit") {
        this.prefilling = true;
      }
      const itemsFetched = this.mealItems?.length;
      if (itemsFetched) {
        this.mealClassItems = this.mealItems;
      } else {
        await this.$store.dispatch(actions.GET_MEAL_ITEM_LIST).then(() => {
          this.mealClassItems = this.mealItems;
        });
      }
      if (this.foodTags?.length === 0) {
        await this.$store.dispatch(actions.GET_FOOD_TAGS);
      }
      if (this.menuTypes?.length === 0) {
        await this.$store.dispatch(actions.GET_MENU_TYPES);
      }

      if (this.action === "edit") {
        this.prefillForm();
      }
      this.prefilling = false;
    },
    prefillForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.serviceType[key];
      });
      this.form.daterange = [
        this.serviceType.from_date,
        this.serviceType.to_date,
      ];
      this.form.menu_type_id = this.serviceType.menu_type_id;
      this.form.menu_items = this.serviceType.menu_items_service_type_id.map(
        (item) => {
          return {
            ...this.mealItems.find((it) => it.id === item),
            added_combos: [],
            mapped_combos: this.serviceType?.visible_combos?.find(
              (visible_combo) =>
                visible_combo.main_item_service_type_id === item,
            )?.combos,
          };
        },
      );
      console.log(this.form.menu_items, "from prefill form");
    },
    getItems() {
      this.$store.dispatch(actions.GET_MEAL_ITEM_LIST).then(() => {
        this.mealClassItems = this.mealItems;
        this.form.menu_items = this.form.menu_items.map((item) => {
          return {
            ...item,
            ...this.mealClassItems.find((mItem) => mItem.id === item.id),
          };
        });
      });
    },
    cancel() {
      this.$router.push({
        name: "services.service",
        params: { service: "meal" },
        query: { tab: "menu" },
      });
    },
    next() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.step = this.step === 1 ? 2 : 1;
      });
    },

    addMenu() {
      this.showPublish = true;
    },
    async publish(publish = true) {
      const mappedMealItems = this.form.menu_items.map((item) => ({
        ...item,
        combos: item.combos.map((comb) => ({ ...comb, meal_id: item.id })),
      }));
      const editedCombos = mappedMealItems
        .filter((item) => item.combos.some((com) => com.edited))
        ?.map((item) =>
          item.combos
            ?.map((item) => ({
              combo_id: item.id,
              meal_id: item.meal_id,
              tags_id: item.tags_id,
              calorie: item.calorie,
              image: item.image,
              edited: item.edited,
            }))
            .filter((com) => com.edited),
        )
        .flat();

      const payload = {
        from_date: this.form.daterange[0],
        to_date: this.form.daterange[1],
        menu_type_id: this.form.menu_type_id,
        menu_items: this.form.menu_items.map((item) => item.id),
        is_published: publish,
        menu_items_combos: this.form.menu_items?.map((item) => ({
          meal_id: item.id,
          // meal_combo_ids: item?.preset_combos?.length
          //   ? item?.combos?.map((combo) => combo.id)
          //   : [],
          meal_combo_ids: item?.combos?.map((combo) => combo.id),
        })),
        edited_combos: editedCombos,
      };

      if (this.action === "add") {
        this.adding = true;
        menu
          .publish(payload)
          .then((response) => {
            if (response.data.status) {
              this.showPublish = false;
              this.$message.success(response.data.message);
              this.$router.push({
                name: "services.service",
                params: { service: "meal" },
                query: { tab: "menu" },
              });
            } else {
              this.$message.error(response.data.message);
            }
            this.adding = false;
          })
          .catch((error) => {
            this.showPublish = false;
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      } else {
        this.adding = true;
        menu
          .update({ ...payload, menu_id: this.$route.params.id })
          .then((response) => {
            if (response.data.status) {
              // this.callActions();
              this.prefillForm();
              this.showPublish = false;
              this.$message.success(response.data.message);
              this.$router.push({
                name: "services.service",
                params: { service: "meal" },
                query: { tab: "menu" },
              });
            } else {
              this.$message.error(response.data.message);
            }
            this.adding = false;
          })
          .catch((error) => {
            this.showPublish = false;
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      }
    },
    async update() {
      this.updating = true;
      await this.publish(false);
      this.updating = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ingredient-add,
.combo-add {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--eden-green-senary);
    border-radius: 10px;
    margin-right: 5px;

    i {
      color: var(--eden-green-primary);
      font-size: 0.75rem;
      font-weight: 60;
    }
  }

  p {
    color: var(--eden-green-primary);
    font-size: 0.825rem;
  }
}

.ingredient-remove {
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.combo-empty {
  width: 50%;
  margin: 0 auto;
  border: 1px solid var(--eden-grey-quinary);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  .combo-add {
    margin-top: 0;
  }
}
.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.wider {
  width: 75% !important;
}
</style>
