<template>
  <div>
    <eden-form-section
      title="Combinations Review"
      subtitle="Select the combinations you want customers to pick from in this menu."
    ></eden-form-section>
    <el-row v-for="(meal, index) in setMenuItems" :key="index" type="flex">
      <el-col :md="24">
        <el-form-item>
          <div class="card mb-10">
            <div class="header mb-10">
              <span class="text-bold">{{ meal?.name }}</span>
              <span class="text-cursor" @click="removeItem(meal.id)"
                ><i class="eden-icon-remove font-sm mr-1" /> Remove meal
                item</span
              >
            </div>
            <el-row
              type="flex"
              class="flex-wrap is-align-center font-sm justify-between"
              v-if="meal.combos?.length"
            >
              <el-col :md="12" v-for="(combo, i) in meal.combos" :key="i">
                <div
                  class="combo-card is-flex is-align-center"
                  :class="{
                    'w-120': combo?.combo == meal.name,
                    'no-image': !combo.image,
                  }"
                >
                  <!-- <el-checkbox v-if="combo.combo != meal?.name" class="mr-1" /> -->
                  <div class="eden-meal--image">
                    <img
                      v-if="combo.image != null"
                      :src="getThumbnailFromCloudinary(combo.image)"
                      alt="Image"
                    />
                  </div>
                  <div class="additional-info">
                    <div class="text-bold mr-10">
                      {{ formatText(combo.combo) }}
                    </div>
                    <div v-for="tag in combo.tags_id" :key="tag.id">
                      {{ mapFoodTag(tag)?.name }}
                    </div>
                    <div v-if="combo.tags_id?.length === 0 || !combo.tags_id">
                      No tags added
                    </div>
                    <div>
                      {{
                        combo.calorie
                          ? combo.calorie + " cals"
                          : "No calories added"
                      }}
                    </div>
                  </div>
                  <div class="text-cursor">
                    <span
                      @click="
                        editCombo({
                          ...combo,
                          meal_index: index,
                          combo_index: i,
                        })
                      "
                      class="eden-icon-edit font-sm mr-1"
                    ></span>
                    <span
                      @click="removeCombo(i, index)"
                      v-if="combo.combo != meal?.name"
                      class="eden-icon-delete font-sm"
                    ></span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div
              v-if="meal.combos?.length && meal.combos[0]?.combo != meal?.name"
              class="text-center mt-10 mb-10 text-secondary text-cursor"
              @click="setCombination(meal)"
            >
              <i class="eden-icon-add font-sm mr-1 text-secondary" /> Add a
              combination
            </div>
            <div
              v-else-if="!meal.combos?.length"
              class="text-center mt-10 mb-10 text-secondary text-cursor"
              @click="setCombination(meal)"
            >
              <i class="eden-icon-add font-sm mr-1 text-secondary" /> Add a
              combination
            </div>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <combinations
      :show.sync="showCombinations"
      :item="menuItem"
      :isPublished="isPublished"
      @success="addCombination"
      @checkedCombos="checkCombos"
    />
    <eden-confirm-dialog
      title="Remove combo item?"
      button-text="Yes, remove"
      button-type="danger"
      :show.sync="showRemoveCombo"
      @confirm="deleteCombo"
    >
      <p>Are you sure you want to remove this combo from the menu?</p>
    </eden-confirm-dialog>
    <eden-confirm-dialog
      title="Remove meal item?"
      button-text="Yes, remove"
      button-type="danger"
      :show.sync="showRemoveItem"
      @confirm="deleteItem"
    >
      <p>Are you sure you want to remove this meal item from the menu?</p>
    </eden-confirm-dialog>
    <combination-form
      :show.sync="showComboForm"
      :action="'edit'"
      :item="comboToEdit"
      :isPublished="isPublished"
      @success="updateCombinations"
      @update:combo="updateCombinations"
    />
  </div>
</template>

<script>
import Combinations from "@/components/Services/Meal/Menu/NewMenu/Combinations";
import CombinationForm from "@/components/Services/Meal/Menu/NewMenu/CombinationForm";

export default {
  name: "CombinationsReview",
  props: {
    menuItems: {
      type: Array,
      default() {
        return [];
      },
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Combinations,
    CombinationForm,
  },
  data() {
    return {
      showCombinations: false,
      menuItem: {},
      showRemoveItem: false,
      showRemoveCombo: false,
      itemId: null,
      showComboForm: false,
      comboToEdit: {},
      actionItems: {},
    };
  },
  mounted() {
    this.getCombos();
  },
  computed: {
    setMenuItems: {
      get() {
        return this.menuItems;
      },
      set(value) {
        this.$emit("update:menuItems", value);
      },
    },
    food_tags() {
      return this.$store.getters.food_tags;
    },
  },
  methods: {
    getCombos() {
      this.setMenuItems.sort((a, b) => {
        let firstItem = a.name?.toLowerCase(),
          nextItem = b.name?.toLowerCase();

        if (firstItem < nextItem) {
          return -1;
        }
        if (firstItem > nextItem) {
          return 1;
        }
        return 0;
      });
      this.setMenuItems.map((item) => {
        let combos;
        if (
          item?.class_category != null &&
          item?.class_category.includes("juices")
        ) {
          // combos = [
          //   {
          //     id: item.id,
          //     ...item,
          //     combo: item?.name,
          //     image: item.image_url,
          //   },
          // ];
          combos = [];
        } else {
          if (item.mapped_combos?.length === 0 || !item.mapped_combos) {
            combos = [];
          } else {
            combos = item.mapped_combos.map((combo) => {
              let availableSides = Object.keys(combo).filter((item) =>
                item.includes("sides_name"),
              );
              if (!availableSides.length) {
                return [];
              } else {
                let sideItem = "";
                availableSides.map((side) => {
                  if (combo[side])
                    sideItem += ", " + this.formatText(combo[side]);
                });
                return {
                  ...combo,
                  combo: sideItem.slice(1),
                  image: combo.image,
                  checked: true,
                  meal_id: item.id,
                };
              }
            });
          }
        }

        item.combos = combos;
        return item;
      });
    },
    updateCombinations(evt) {
      // this.$emit("success");
      console.log("edited from combinations review", evt);
      this.setMenuItems[evt.meal_index].combos[evt.combo_index] = evt;
    },
    mapFoodTag(tag_id) {
      return this.food_tags.find((item) => item.id === tag_id);
    },
    checkCombos(value) {
      this.setMenuItems.map((item) => {
        if (item.id === value.id) {
          let itemValue = item;
          itemValue.combos = value?.combos;
          return itemValue;
        } else {
          return item;
        }
      });
    },
    editCombo(combo) {
      this.comboToEdit = combo;
      console.log("combo to edit", combo);

      this.showComboForm = true;
    },
    removeCombo(comboIndex, mealItemIndex) {
      this.showRemoveCombo = true;
      this.actionItems = {
        comboIndex,
        mealItemIndex,
      };
    },
    removeItem(index) {
      this.showRemoveItem = true;
      this.itemId = index;
    },
    deleteCombo() {
      const mealInAction = this.setMenuItems[this.actionItems.mealItemIndex];

      mealInAction?.combos?.splice(this.actionItems.comboIndex, 1);

      this.setMenuItems[this.actionItems.mealItemIndex] = mealInAction;
      this.showRemoveCombo = false;
    },
    deleteItem() {
      this.setMenuItems = this.setMenuItems.filter(
        (meal) => meal.id !== this.itemId,
      );
      this.showRemoveItem = false;
    },
    setCombination(value) {
      this.menuItem = value;
      this.showCombinations = true;
    },
    addCombination(newCombo) {
      console.log("newwww", newCombo);
      this.menuItem.added_combos = [
        ...this.menuItem?.added_combos,
        ...newCombo,
      ];
      console.log("newwww", this.menuItem.added_combos);
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-wrap {
  flex-wrap: wrap;
}
.card {
  padding: 3% 4%;
  background-color: var(--eden-grey-octonary);
  border-radius: 8px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.menu-meals {
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 12px 0px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 8px;
    font-size: 12px;
  }

  .meals {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;

    .item {
      padding: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin: 0 8px 8px;
      width: auto;

      i {
        margin-left: 10px;
        color: #798b83;
        cursor: pointer;
      }
      &.invalid {
        color: #e36262;
        i {
          color: #e36262;
        }
      }
    }
  }
}
.eden-meal--image {
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 100%;
  background: #798b83;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 100%;
    background: #798b83;
  }

  + div {
    width: 70%;
  }
}
.combo-card {
  background-color: var(--white);
  padding: 8px;
  border-radius: 8px;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 20px;

  &.no-image {
    border: 1px solid var(--eden-red-quinary);
  }
}
.additional-info {
  line-height: 18px;
}
.w-120 {
  width: 120%;
}
</style>
