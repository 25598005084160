var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-form-section',{attrs:{"title":"Combinations Review","subtitle":"Select the combinations you want customers to pick from in this menu."}}),_vm._l((_vm.setMenuItems),function(meal,index){return _c('el-row',{key:index,attrs:{"type":"flex"}},[_c('el-col',{attrs:{"md":24}},[_c('el-form-item',[_c('div',{staticClass:"card mb-10"},[_c('div',{staticClass:"header mb-10"},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(meal?.name))]),_c('span',{staticClass:"text-cursor",on:{"click":function($event){return _vm.removeItem(meal.id)}}},[_c('i',{staticClass:"eden-icon-remove font-sm mr-1"}),_vm._v(" Remove meal item")])]),(meal.combos?.length)?_c('el-row',{staticClass:"flex-wrap is-align-center font-sm justify-between",attrs:{"type":"flex"}},_vm._l((meal.combos),function(combo,i){return _c('el-col',{key:i,attrs:{"md":12}},[_c('div',{staticClass:"combo-card is-flex is-align-center",class:{
                  'w-120': combo?.combo == meal.name,
                  'no-image': !combo.image,
                }},[_c('div',{staticClass:"eden-meal--image"},[(combo.image != null)?_c('img',{attrs:{"src":_vm.getThumbnailFromCloudinary(combo.image),"alt":"Image"}}):_vm._e()]),_c('div',{staticClass:"additional-info"},[_c('div',{staticClass:"text-bold mr-10"},[_vm._v(" "+_vm._s(_vm.formatText(combo.combo))+" ")]),_vm._l((combo.tags_id),function(tag){return _c('div',{key:tag.id},[_vm._v(" "+_vm._s(_vm.mapFoodTag(tag)?.name)+" ")])}),(combo.tags_id?.length === 0 || !combo.tags_id)?_c('div',[_vm._v(" No tags added ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(combo.calorie ? combo.calorie + " cals" : "No calories added")+" ")])],2),_c('div',{staticClass:"text-cursor"},[_c('span',{staticClass:"eden-icon-edit font-sm mr-1",on:{"click":function($event){return _vm.editCombo({
                        ...combo,
                        meal_index: index,
                        combo_index: i,
                      })}}}),(combo.combo != meal?.name)?_c('span',{staticClass:"eden-icon-delete font-sm",on:{"click":function($event){return _vm.removeCombo(i, index)}}}):_vm._e()])])])}),1):_vm._e(),(meal.combos?.length && meal.combos[0]?.combo != meal?.name)?_c('div',{staticClass:"text-center mt-10 mb-10 text-secondary text-cursor",on:{"click":function($event){return _vm.setCombination(meal)}}},[_c('i',{staticClass:"eden-icon-add font-sm mr-1 text-secondary"}),_vm._v(" Add a combination ")]):(!meal.combos?.length)?_c('div',{staticClass:"text-center mt-10 mb-10 text-secondary text-cursor",on:{"click":function($event){return _vm.setCombination(meal)}}},[_c('i',{staticClass:"eden-icon-add font-sm mr-1 text-secondary"}),_vm._v(" Add a combination ")]):_vm._e()],1)])],1)],1)}),_c('combinations',{attrs:{"show":_vm.showCombinations,"item":_vm.menuItem,"isPublished":_vm.isPublished},on:{"update:show":function($event){_vm.showCombinations=$event},"success":_vm.addCombination,"checkedCombos":_vm.checkCombos}}),_c('eden-confirm-dialog',{attrs:{"title":"Remove combo item?","button-text":"Yes, remove","button-type":"danger","show":_vm.showRemoveCombo},on:{"update:show":function($event){_vm.showRemoveCombo=$event},"confirm":_vm.deleteCombo}},[_c('p',[_vm._v("Are you sure you want to remove this combo from the menu?")])]),_c('eden-confirm-dialog',{attrs:{"title":"Remove meal item?","button-text":"Yes, remove","button-type":"danger","show":_vm.showRemoveItem},on:{"update:show":function($event){_vm.showRemoveItem=$event},"confirm":_vm.deleteItem}},[_c('p',[_vm._v("Are you sure you want to remove this meal item from the menu?")])]),_c('combination-form',{attrs:{"show":_vm.showComboForm,"action":'edit',"item":_vm.comboToEdit,"isPublished":_vm.isPublished},on:{"update:show":function($event){_vm.showComboForm=$event},"success":_vm.updateCombinations,"update:combo":_vm.updateCombinations}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }